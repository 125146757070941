/* eslint-disable @typescript-eslint/no-explicit-any */
import { adminDataSource } from '@/backend/dataSources';
import { getLangFromLocale } from '@/backend/utils/helpers';
import { useGetTranslatableResourcesQuery } from '@/lib/shopify-admin/__generated__/GetTranslatableResource';
import type { Translation } from '@/lib/shopify-admin/__generated__/types';
import {
  ALGOLIA_ARTICLES_BASE,
  ALGOLIA_COLLECTION_BASE,
  ALGOLIA_NAILART_BASE,
  ALGOLIA_PRODUCTS_BASE,
  ALGOLIA_SUGGESTIONS_BASE,
  ALGOLIA_EDU_COURSE_BASE,
  LOCALES,
} from '@/root/constants';
import { getCountryCodeFromLocale } from '@/utils/localization';
import type { StoreLocale } from '@store-constants';
import { idFromShopifyId } from './helpers';

/**
 * Algolia Constants
 *
 * Below Algolia types and index lookup functions are defined
 * These are derived from our constant values
 */

const generateAlgoliaIndex = (
  locale: StoreLocale,
  baseIndex: string
): string => {
  const countryCode = getCountryCodeFromLocale(locale);
  const sameCountryLocales = LOCALES.filter(
    (l) => l.split('-')[1] === countryCode
  );
  if (sameCountryLocales.length > 1) {
    return `${countryCode}${baseIndex}`;
  }
  return `${locale}${baseIndex}`;
};
type AlgoliaProductBase = typeof ALGOLIA_PRODUCTS_BASE;
type AlgoliaCollectionBase = typeof ALGOLIA_COLLECTION_BASE;
type AlgoliaArticlesBase = typeof ALGOLIA_ARTICLES_BASE;
type AlgoliaNailartBase = typeof ALGOLIA_NAILART_BASE;
type AlgoliaSuggestionsBase = typeof ALGOLIA_SUGGESTIONS_BASE;
type AlgoliaEduCourseBase = typeof ALGOLIA_EDU_COURSE_BASE;

export type AlgoliaProductIndex =
  | `${StoreLocale}${AlgoliaProductBase}`
  | string;
/**
 * algoliaProductIndex function
 * return the product index of a given locale
 *
 * @param locale
 */
export const algoliaProductIndex = (
  locale: StoreLocale
): AlgoliaProductIndex => {
  return generateAlgoliaIndex(locale, ALGOLIA_PRODUCTS_BASE);
};

export type AlgoliaCollectionIndex =
  | `${StoreLocale}${AlgoliaCollectionBase}`
  | string;
/**
 * algoliaProductIndex function
 * return the product index of a given locale
 *
 * @param locale
 */
export const algoliaCollectionIndex = (
  locale: StoreLocale
): AlgoliaCollectionIndex => {
  return generateAlgoliaIndex(locale, ALGOLIA_COLLECTION_BASE);
};

export type AlgoliaArticlesIndex =
  | `${StoreLocale}${AlgoliaArticlesBase}`
  | string;
/**
 * algoliaArticlesIndex function
 * return the articles index of a given locale
 *
 * @param locale
 */
export const algoliaArticlesIndex = (
  locale: StoreLocale
): AlgoliaArticlesIndex => {
  return `${locale}${ALGOLIA_ARTICLES_BASE}`;
};

export type AlgoliaNailIndex = `${StoreLocale}${AlgoliaNailartBase}` | string;
/**
 * algoliaNailIndex function
 * return the nail art index of a given locale
 *
 * @param locale
 */
export const algoliaNailIndex = (locale: StoreLocale): AlgoliaNailIndex => {
  return `${locale}${ALGOLIA_NAILART_BASE}`;
};

export type AlgoliaSuggestionsIndex =
  | `${StoreLocale}${AlgoliaSuggestionsBase}`
  | string;
/**
 * algoliaNailIndex function
 * return the nail art index of a given locale
 *
 * @param locale
 */
export const algoliaSuggestionsIndex = (
  locale: StoreLocale
): AlgoliaSuggestionsIndex => {
  return `${locale}${ALGOLIA_SUGGESTIONS_BASE}`;
};

export type AlgoliaEduCourseIndex =
  | `${StoreLocale}${AlgoliaEduCourseBase}`
  | string;
/**
 * algoliaNailIndex function
 * return the nail art index of a given locale
 *
 * @param locale
 */
export const algoliaEduCourseIndex = (
  locale: StoreLocale
): AlgoliaEduCourseIndex => {
  return `${locale}${ALGOLIA_EDU_COURSE_BASE}`;
};

/**
 * AlgoliaIndex Type
 *
 * Returns a list union type of all possible algolia index's
 */
export type AlgoliaIndex =
  | AlgoliaProductIndex
  | AlgoliaCollectionIndex
  | AlgoliaArticlesIndex
  | AlgoliaNailIndex
  | AlgoliaSuggestionsIndex
  | AlgoliaEduCourseIndex;

/**
 * Algolia Indexes labels
 */
export type AlgoliaIndexLabel =
  | 'product'
  | 'collection'
  | 'articles'
  | 'nailart'
  | 'suggestions'
  | 'eduCourse';

const algoliaIndexLabelIndexMap = new Map();
algoliaIndexLabelIndexMap.set('product', algoliaProductIndex);
algoliaIndexLabelIndexMap.set('collection', algoliaCollectionIndex);
algoliaIndexLabelIndexMap.set('articles', algoliaArticlesIndex);
algoliaIndexLabelIndexMap.set('nailart', algoliaNailIndex);
algoliaIndexLabelIndexMap.set('suggestions', algoliaSuggestionsIndex);

/**
 * algoliaIndex function
 *
 * Returns a valid algolia index based on a locale and index type
 *
 * @param locale - StoreIndex
 * @param index - 'product' | 'collection' | 'articles' | 'nailart'
 */
export const algoliaIndex = (
  locale: StoreLocale,
  index: AlgoliaIndexLabel
): AlgoliaIndex => {
  const matchIndexFunction = algoliaIndexLabelIndexMap.get(index);
  if (matchIndexFunction) {
    return matchIndexFunction(locale);
  }

  return algoliaProductIndex(locale);
};

/**
 * Get the Algolia Index Label based on an Algolia Index string.
 * @param locale StoreLocale
 * @param index AlgoliaIndex
 * @returns AlgoliaIndexLabel
 */
export const algoliaIndexToIndexLabel = (
  locale: StoreLocale,
  index: AlgoliaIndex
): AlgoliaIndexLabel | undefined => {
  for (const [key, value] of algoliaIndexLabelIndexMap.entries()) {
    const resolvedValue = value(locale);
    if (resolvedValue === index) {
      return key;
    }
  }
};

// types for the synchronization to Algolia

// Define the structure of a Shopify product
export type ShopifyProduct = {
  objectID: string;
  id: string;
  status?: string;
  image: string;
  price: number;
  product_image: string;
  compare_at_price: number;
  inventory_quantity: number;
  inventory_available: boolean;
  tags: string[];
  product_type: string;
  named_tags: {
    [key: string]: string | boolean | number | Array<string | boolean | number>;
  };
  metafields: number;
  meta_en?: Record<string, string>;
  meta_de?: Record<string, string>;
  meta_fr?: Record<string, string>;
  meta_it?: Record<string, string>;
  collections: string[];
  collection_ids: string[];
};

// Define the structure of a localized Shopify product
export type LocalizedShopifyProduct = {
  [K in keyof ShopifyProduct as `${K}_${
    | 'en'
    | 'de'
    | 'fr'
    | 'it'}`]?: ShopifyProduct[K];
};

// Define the locales for the meta fields
export type MetaLocale = 'en' | 'de' | 'fr' | 'it';

// Define the structure of a localized Shopify product with meta fields
export type LocalizedShopifyProductWithMeta = LocalizedShopifyProduct & {
  [K in `meta_${MetaLocale}`]?: Record<string, string>;
};

// Define the structure of a product map
export type ProductMap = Map<
  string,
  ShopifyProduct & LocalizedShopifyProductWithMeta
>;

// Declare the type of the translatedMetafields object
export type TranslatedMetafields = {
  [resourceId: string]: Translation['value'];
};

// helpers for the synchronization to Algolia

/**
 * Creates named tags from a list of tags.
 *
 * @param {string[]} tags - The list of tags.
 * @returns {Record<string, string | boolean | number>} The named tags.
 */
export const createNamedTags = (tags: string[]) => {
  const namedTags: Record<string, string | boolean | number> = {};
  tags.forEach((tag) => {
    const [key, value] = tag.split(':');
    if (value === 'true' || value === 'false') {
      namedTags[key] = value === 'true';
    } else if (!isNaN(Number(value))) {
      namedTags[key] = Number(value);
    } else {
      namedTags[key] = value;
    }
  });
  return namedTags;
};

/**
 * Sets a product in the product map.
 *
 * @param {ProductMap} productMap - The product map.
 * @param {string} productId - The product ID.
 * @param {ShopifyProduct & LocalizedShopifyProductWithMeta} productData - The product data.
 */
export const setProductInMap = (
  productMap: ProductMap,
  productId: string,
  productData: ShopifyProduct & LocalizedShopifyProductWithMeta
) => {
  productMap.set(productId, productData);
};

/**
 * Creates a product.
 *
 * @param {any} product - The product.
 * @param {StoreLocale} locale - The locale.
 * @returns {ShopifyProduct & LocalizedShopifyProductWithMeta} The created product.
 */
export const createProduct = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  product: any,
  locale: StoreLocale
): ShopifyProduct & LocalizedShopifyProductWithMeta => {
  const lang = getLangFromLocale(locale);
  return {
    objectID: idFromShopifyId(product.id),
    id: product.id,
    status: product.status,
    [`title_${lang}`]: product.translations['title'] || product.title,
    image: product.featuredImage?.url || '',
    [`handle_${lang}`]: product.translations['handle'] || product.handle,
    price: Number(product.priceRangeV2.minVariantPrice.amount),
    product_image: product.featuredImage?.url || '',
    compare_at_price: Number(product.priceRangeV2.minVariantPrice.amount),
    inventory_quantity: product.totalInventory,
    inventory_available: product.variants?.nodes.some(
      (variant: any) => variant.availableForSale
    ),
    tags: product.tags,
    product_type: product.productType,
    named_tags: createNamedTags(product.tags),
    collections: product.collections?.nodes.map(
      (collection: any) => collection.handle
    ),
    collection_ids: product.collections?.nodes.map(
      (collection: any) => collection.id.split('/').pop() || ''
    ),
    metafields: product.metafields?.nodes.length,
  };
};

/**
 * Gets translated metafields.
 *
 * @param {string[]} id - The IDs.
 * @param {StoreLocale} locale - The locale.
 * @returns {Promise<TranslatedMetafields>} The translated metafields.
 */
export const getTranslatedMetafields = async (
  id: string[],
  locale: StoreLocale
) => {
  const result = await useGetTranslatableResourcesQuery.fetcher(
    adminDataSource(locale),
    {
      ids: id,
      locale: getLangFromLocale(locale) || 'en',
    }
  )();

  // Map the result to an object with keys as metafield keys and values as translations
  const translatedMetafields: TranslatedMetafields = {};
  result.translatableResourcesByIds?.nodes.forEach((node) => {
    if (node.translations && node.translations.length > 0) {
      translatedMetafields[node.resourceId] = node.translations[0].value;
    }
  });

  return translatedMetafields;
};

// Define the structure of a Shopify collection
export type ShopifyCollection = {
  objectID: string;
  productsCount: number;
  updatedAt: string;
  [key: string]: string | number;
};

// Define the structure of a localized Shopify collection
export type LocalizedShopifyCollection = {
  [K in keyof ShopifyCollection as `${K}_${
    | 'en'
    | 'de'
    | 'fr'
    | 'it'}`]?: ShopifyCollection[K];
};

// Function to set a collection in the collection map
export const setCollectionInMap = (
  collection: ShopifyCollection & LocalizedShopifyCollection,
  shopifyCollections: Map<
    string,
    ShopifyCollection & LocalizedShopifyCollection
  >
) => {
  shopifyCollections.set(collection.objectID, collection);
};

// Function to create a collection
export const createCollection = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  collection: any,
  locale: StoreLocale
): ShopifyCollection & LocalizedShopifyCollection => {
  const lang = getLangFromLocale(locale);
  return {
    objectID: idFromShopifyId(collection.id),
    [`title_${lang}`]: collection.translations['title'] || collection.title,
    [`handle_${lang}`]: collection.translations['handle'] || collection.handle,
    productsCount: collection.productsCount,
    updatedAt: collection.updatedAt,
  };
};
