/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/naming-convention */
import type { IronSessionOptions } from 'iron-session/edge';
import { localeToStore, opiChStore } from '@/root/constants';
import type { StoreLocale, Stores } from '@/root/constants';

const storeID = process.env?.STORE_ID ?? 'opi';

export const STORES_TO_PRIVATE_TOKEN = new Map<Stores, string>([
  ['opi-uki', process.env.EN_GB_ADMIN_TOKEN ?? ''],
  ['opi-us', process.env.EN_US_ADMIN_TOKEN ?? ''],
  ['opi-de', process.env.DE_DE_ADMIN_TOKEN ?? ''],
  ['opi-it', process.env.IT_IT_ADMIN_TOKEN ?? ''],
  ['opi-fr', process.env.FR_FR_ADMIN_TOKEN ?? ''],
  ['opi-es', process.env.ES_ES_ADMIN_TOKEN ?? ''],
  ['opi-jp', process.env.JP_JP_ADMIN_TOKEN ?? ''],
  [opiChStore, process.env.CH_ADMIN_TOKEN ?? ''],
]);

export const STORES_TO_PRIVATE_WISHLIST_TOKEN = new Map<Stores, string>([
  ['opi-uki', process.env.EN_GB_WISHLIST_TOKEN ?? ''],
  ['opi-us', process.env.EN_US_WISHLIST_TOKEN ?? ''],
  ['opi-de', process.env.DE_DE_WISHLIST_TOKEN ?? ''],
  ['opi-it', process.env.IT_IT_WISHLIST_TOKEN ?? ''],
  ['opi-fr', process.env.FR_FR_WISHLIST_TOKEN ?? ''],
  ['opi-es', process.env.ES_ES_WISHLIST_TOKEN ?? ''],
  ['opi-jp', process.env.JP_JP_WISHLIST_TOKEN ?? ''],
  [opiChStore, process.env.CH_WISHLIST_TOKEN ?? ''],
]);

export const STORES_TO_ADMIN_SECRET = new Map<Stores, string>([
  ['opi-uki', process.env.EN_GB_ADMIN_SECRET ?? ''],
  ['opi-us', process.env.EN_US_ADMIN_SECRET ?? ''],
  ['opi-de', process.env.DE_DE_ADMIN_SECRET ?? ''],
  ['opi-it', process.env.IT_IT_ADMIN_SECRET ?? ''],
  ['opi-fr', process.env.FR_FR_ADMIN_SECRET ?? ''],
  ['opi-es', process.env.ES_ES_ADMIN_SECRET ?? ''],
  ['opi-jp', process.env.JP_JP_ADMIN_SECRET ?? ''],
  [opiChStore, process.env.CH_ADMIN_SECRET ?? ''],
]);

export const CONTENTFUL_PREVIEW_CHECK_TOKEN = '1E4A9r678xUKsDIOf5TzxDaapp5R';

export const WMW_SYNC_KEY = process.env.WMW_SYNC_KEY ?? '';

const COMMON_IRON_SESSION_OPTIONS = {
  password: {
    1: process.env.SESSION_KEY ?? '',
  },
  cookieName: `${storeID}_storefront_session`,

  // secure: true should be used in production (HTTPS) but can't be used in development (HTTP)
  cookieOptions: {
    secure: process.env.NODE_ENV === 'production',
  },
};

/**
 * Set iron-session options
 */
export const IRON_SESSION_OPTIONS: {
  [Locale in StoreLocale]: IronSessionOptions;
} = {
  'en-US': {
    ...COMMON_IRON_SESSION_OPTIONS,
    cookieName: `${storeID}_${localeToStore('en-US')}_storefront_session`,
  },
  'en-GB': {
    ...COMMON_IRON_SESSION_OPTIONS,
    cookieName: `${storeID}_${localeToStore('en-GB')}_storefront_session`,
  },
  'en-CH': {
    ...COMMON_IRON_SESSION_OPTIONS,
    cookieName: `${storeID}_${localeToStore('en-CH')}_storefront_session`,
  },
  'fr-CH': {
    ...COMMON_IRON_SESSION_OPTIONS,
    cookieName: `${storeID}_${localeToStore('fr-CH')}_storefront_session`,
  },
  'de-CH': {
    ...COMMON_IRON_SESSION_OPTIONS,
    cookieName: `${storeID}_${localeToStore('de-CH')}_storefront_session`,
  },
  'it-CH': {
    ...COMMON_IRON_SESSION_OPTIONS,
    cookieName: `${storeID}_${localeToStore('it-CH')}_storefront_session`,
  },
  'de-DE': {
    ...COMMON_IRON_SESSION_OPTIONS,
    cookieName: `${storeID}_${localeToStore('de-DE')}_storefront_session`,
  },
  'it-IT': {
    ...COMMON_IRON_SESSION_OPTIONS,
    cookieName: `${storeID}_${localeToStore('it-IT')}_storefront_session`,
  },
  'fr-FR': {
    ...COMMON_IRON_SESSION_OPTIONS,
    cookieName: `${storeID}_${localeToStore('fr-FR')}_storefront_session`,
  },
  'es-ES': {
    ...COMMON_IRON_SESSION_OPTIONS,
    cookieName: `${storeID}_${localeToStore('es-ES')}_storefront_session`,
  },
  // 'jp-JP': {
  //   ...COMMON_IRON_SESSION_OPTIONS,
  //   cookieName: `${storeID}_${localeToStore('jp-JP')}_storefront_session`,
  // },
} as const;
