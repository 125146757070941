import type { UiState } from 'instantsearch.js';
import { useRouter } from 'next/router';
import { useLayoutEffect } from 'react';
import { useInstantSearch } from 'react-instantsearch-hooks';
import { storeLocale } from '@/root/constants';
import type { StoreLocale } from '@/root/constants';
import type { AlgoliaIndex } from '@/utils/algoliaConstants';
import { algoliaProductIndex } from '@/utils/algoliaConstants';

/**
 * Algolia Middleware component
 * - Used to check the Algolia index state to see if a certain index has filters
 *   applied.
 * - By default, uses the algolia default product index.
 * - The Algolia index to check can be overridden with the `indexName` prop.
 * @param setAlgoliaRefinement - setState function void
 */
export const FilterAppliedMiddleware = ({
  setAlgoliaRefinement,
  locale,
  indexName,
}: {
  setAlgoliaRefinement: (filtersApplied: boolean) => void;
  locale?: StoreLocale;
  indexName?: AlgoliaIndex;
}) => {
  const { use } = useInstantSearch();

  const { locale: routerLocale } = useRouter();
  const localeValue = locale ?? storeLocale(routerLocale);
  const defaultProductIndex = algoliaProductIndex(localeValue);

  const index = indexName || defaultProductIndex;

  useLayoutEffect(() => {
    return use(({ instantSearchInstance }) => {
      return {
        onStateChange({ uiState }) {
          const filtersApplied = areFiltersApplied(uiState, index);
          setAlgoliaRefinement(filtersApplied);
        },
        subscribe() {
          const uiState = instantSearchInstance.getUiState();
          const filtersApplied = areFiltersApplied(uiState, index);
          setAlgoliaRefinement(filtersApplied);
        },
      };
    });
  });

  return null;
};

/**
 * Check the UI State to see if any Algolia filters/refinements are set.
 * @param uiState - Algolia UI State
 * @param indexName - Algolia Index. Use check the Algolia index in UI State
 */
export const areFiltersApplied = (
  uiState: UiState,
  indexName: AlgoliaIndex
) => {
  // Custom logic
  const indexState = uiState[indexName];

  // True if any Algolia state values applied
  return (
    !!indexState?.menu ||
    !!indexState?.ratingMenu ||
    !!indexState?.numericMenu ||
    !!indexState?.query ||
    !!indexState?.sortBy ||
    !!indexState?.geoSearch ||
    !!indexState?.refinementList ||
    !!indexState?.relevantSort
  );
};
