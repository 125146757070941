/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import type { ProductCardProduct } from '@we-make-websites/ui-lib';
import type { BaseHit } from 'instantsearch.js';
import { useRouter } from 'next/router';
import { storeLocale } from '@/root/constants';
import { formatAlgoliaProductHit } from '@/utils/format/algoliaHit';
import { ProductCardConnected } from '../productCard/ProductCardConnected';

type AlgoliaMeta = {
  [namespace: string]: {
    [key: string]: string | boolean | number;
  };
};

export type AlgoliaProductHit = BaseHit & {
  id: number;
  title: string;
  image: string;
  handle: string;
  price: number;
  product_image: string;
  compare_at_price: number;
  inventory_quantity: number;
  tags: string[];
  meta: AlgoliaMeta;
  named_tags: {
    [key: string]: string | boolean | number | Array<string | boolean | number>;
  };
  product_type: string;
  [key: string]: any;
};

export const ProductCardHit = ({
  hit,
  collectionHandle,
}: {
  hit: AlgoliaProductHit;
  collectionHandle?: string;
}) => {
  const { locale: routerLocale } = useRouter();
  const locale = storeLocale(routerLocale);
  // Extract the language from the locale
  const lang = locale.includes('-') ? locale.split('-')[0] : locale;

  // Modify the properties of the hit object
  Object.keys(hit).forEach((key) => {
    const originalKey = key.split('_')[0];
    const localizedKey = `${originalKey}_${lang}`;
    if (hit.hasOwnProperty(localizedKey)) {
      hit[originalKey] = hit[localizedKey];
    }
  });

  const product: ProductCardProduct = formatAlgoliaProductHit({
    hit,
    collectionHandle,
    locale,
  });

  return (
    <article style={{ height: '100%' }} data-test-id="productCard">
      <ProductCardConnected
        hit={hit}
        product={product}
        isLoading={false}
        location="productGrid"
      />
    </article>
  );
};
